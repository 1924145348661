@import '/src/styles/helpers';

.background {
  width: 100vw;
  height: 100vh; // fallback
  height: calc(var(--vh, 1vh) * 100);
  max-height: 100vh; // fallback
  max-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  position: absolute;
  z-index: -1;

  .structure {
    &_top {
      position: absolute;
      left: -354px;
      top: 89px;

      @include media(portrait) {
        left: -194px;
        top: 166px;
      }

      @include media(landscape) {
        top: 86px;
        left: -224px;
      }

      @include media(desktopSmall) {
        top: 85px;
        left: -108px;
      }

      @include media(desktop) {
        top: 28px;
        left: -253px;
      }

      @include media(desktopBig) {
        top: 28px;
        left: -253px;
      }

      @include media(desktopHuge) {
        top: 28px;
        left: -253px;
      }
    }

    &_bottom {
      position: absolute;
      top: 67%;
      right: -300px;
      transform: rotate(180deg);


      @include media(portrait) {
        top: 56%;
        right: -190px;
      }

      @include media(landscape) {
        top: 56%;
        right: -225px;
      }

      @include media(desktopSmall) {
        top: 54%;
        right: -118px;
      }

      @include media(desktop) {
        top: 48%;
        right: -284px;
      }

      @include media(desktopBig) {
        top: 48%;
        right: -284px;
      }

      @include media(desktopHuge) {
        top: 48%;
        right: -284px;
      }
    }
  }

  .parallax {
    &__draw {
      position: absolute;
      top: 46%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__noise {
      position: absolute;
      right: 0;
      bottom: 131px;
      opacity: 0.3;
      transform: rotate(100deg);

      @include media(portrait) {
        left: 50%;
        transform: translateX(250%);
        bottom: 31%;
        opacity: 1;
      }

      @include media(landscape) {
        left: 50%;
        transform: translateX(250%);
        bottom: 31%;
        opacity: 1;
      }

      @include media(desktopSmall) {
        left: 50%;
        transform: translateX(250%);
        bottom: 31%;
        opacity: 1;
      }

      @include media(desktop) {
        left: 50%;
        transform: translateX(250%);
        bottom: 31%;
        opacity: 1;
      }

      @include media(desktopBig) {
        left: 50%;
        transform: translateX(250%);
        bottom: 31%;
        opacity: 1;
      }

      @include media(desktopHuge) {
        left: 50%;
        transform: translateX(250%);
        bottom: 31%;
        opacity: 1;
      }
    }

    &__border {
      position: absolute;
      bottom: 120px;
      right: 35%;

      @include media(portrait) {
        right: 50%;
        bottom: 23%;
        transform: translateX(800%);
      }

      @include media(landscape) {
        right: 50%;
        bottom: 23%;
        transform: translateX(800%);
      }

      @include media(desktopSmall) {
        right: 50%;
        bottom: 23%;
        transform: translateX(800%);
      }

      @include media(desktop) {
        right: 50%;
        bottom: 23%;
        transform: translateX(800%);
      }

      @include media(desktopBig) {
        right: 50%;
        bottom: 23%;
        transform: translateX(800%);
      }

      @include media(desktopHuge) {
        right: 50%;
        bottom: 23%;
        transform: translateX(800%);
      }
    }

    &__gradient {
      position: absolute;
      top: 114px;
      left: 8%;

      @include media(portrait) {
        top: 197px;
        left: 12%;
      }

      @include media(landscape) {
        top: 197px;
        left: 50%;
        transform: translateX(-800%);
      }

      @include media(desktopSmall) {
        top: 197px;
        left: 50%;
        transform: translateX(-800%);
      }

      @include media(desktop) {
        top: 197px;
        left: 50%;
        transform: translateX(-800%);
      }

      @include media(desktopBig) {
        top: 197px;
        left: 50%;
        transform: translateX(-800%);
      }

      @include media(desktopHuge) {
        top: 197px;
        left: 50%;
        transform: translateX(-800%);
      }
    }

    &__four {
      position: absolute;

      &_top {
        width: 100px;
        height: 112px;
        top: 147px;
        right: -17px;

        @include media(portrait) {
          top: 99px;
          right: 0px;
          width: 104px;
          height: 120px;
        }

        @include media(landscape) {
          width: 128px;
          height: 148px;
        }

        @include media(desktopSmall) {
          width: 172px;
          height: 198px;
        }

        @include media(desktop) {
          width: 204px;
          height: 235px;
        }

        @include media(desktopBig) {
          width: 228px;
          height: 264px;
        }

        @include media(desktopHuge) {
          width: 228px;
          height: 264px;
        }
      }

      &_bottom {
        bottom: -17px;
        left: -25px;
        width: 124px;
        height: 141px;

        @include media(portrait) {
          bottom: -5px;
          left: -6px;
          width: 168px;
          height: 191px;
        }

        @include media(landscape) {
          width: 248px;
          height: 283px;
        }

        @include media(desktopSmall) {
          width: 248px;
          height: 283px;
        }

        @include media(desktop) {
          width: 204px;
          height: 235px;
        }

        @include media(desktopBig) {
          width: 362px;
          height: 416px;
        }

        @include media(desktopHuge) {
          width: 362px;
          height: 416px;
        }
      }
    }
  }

  .ellipse {
    width: 356px;
    height: 20px;
    position: absolute;
    border-radius: 356px;
    opacity: 0.4;
    @include setProperty(background, var(--primary-400), var(--primary-900));
    filter: blur(71.5px);

    @include media(portrait) {
      width: 424px;
      height: 24px;
      border-radius: 424px;
    }

    @include media(landscape) {
      width: 474px;
      height: 28px;
      border-radius: 474px;
    }

    @include media(desktopSmall) {
      width: 824px;
      height: 48px;
      border-radius: 824px;
    }

    @include media(desktop) {
      width: 848px;
      height: 49px;
      border-radius: 848px;
    }

    @include media(desktopBig) {
      width: 1118px;
      height: 58px;
      border-radius: 1118px;
    }

    @include media(desktopHuge) {
      width: 1118px;
      height: 58px;
      border-radius: 1118px;
    }

    &__top {
      left: -74px;
      top: 10px;
      transform: rotate(-30deg);

      @include media(portrait) {
        left: -103px;
        top: 93px;
      }

      @include media(landscape) {
        left: -157px;
        top: 0;
      }

      @include media(desktopSmall) {
        left: -407px;
        top: 10px;
      }

      @include media(desktop) {
        left: -407px;
        top: 0;
      }

      @include media(desktopBig) {
        left: -407px;
        top: 0px;
      }

      @include media(desktopHuge) {
        left: -407px;
        top: 0px;
      }
    }

    &__bottom {
      right: -75px;
      bottom: -47px;
      transform: rotate(-30.837deg);

      @include media(portrait) {
        right: -96px;
        bottom: -33px;
      }

      @include media(landscape) {
        right: -105px;
        bottom: -45px;
      }

      @include media(desktopSmall) {
        right: -310px;
        bottom: -85px;
      }

      @include media(desktop) {
        right: -361px;
        bottom: -66px;
      }

      @include media(desktopBig) {
        right: -307px;
        bottom: -230px;
      }

      @include media(desktopHuge) {
        right: -307px;
        bottom: -230px;
      }
    }

    &__bottom_left {
      left: -83px;
      bottom: -66px;
      transform: rotate(45deg);

      @include media(portrait) {
        left: -279px;
        bottom: -159px;
      }

      @include media(landscape) {
        left: -112px;
        bottom: -74px;
      }

      @include media(desktopSmall) {
        left: -279px;
        bottom: -159px;
      }

      @include media(desktop) {
        left: -279px;
        bottom: -159px;
      }

      @include media(desktopBig) {
        left: -279px;
        bottom: -159px;
      }

      @include media(desktopHuge) {
        left: -279px;
        bottom: -159px;
      }
    }

    &__top_right {
      right: -71px;
      top: -50px;
      transform: rotate(45deg);

      @include media(portrait) {
        right: -434px;
        top: -66px;
      }

      @include media(landscape) {
        right: -166px;
        top: -49px;
      }

      @include media(desktopSmall) {
        right: -434px;
        top: -66px;
      }

      @include media(desktop) {
        right: -434px;
        top: -66px;
      }

      @include media(desktopBig) {
        right: -434px;
        top: -66px;
      }

      @include media(desktopHuge) {
        right: -434px;
        top: -66px;
      }
    }
  }
}

.wrapper {
  position: relative;
  --header-height: 72px;
  padding-top: var(--header-height);
  height: 100vh; // fallback
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;
  display: flex;

  .logo {
    position: absolute;
    top: 20px;
    left: 24px;

    @include media(desktopSmall) {
      top: 32px;
      left: 52px;
    }

    @include media(desktop) {
      top: 32px;
      left: 52px;
    }

    @include media(desktopBig) {
      top: 32px;
      left: 52px;
    }

    @include media(desktopHuge) {
      top: 32px;
      left: 52px;
    }
  }

  .content {
    margin: 0 auto;
    padding: 0 24px;
    max-width: 640px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &_marginTop {
      max-width: 569px;
      margin-top: 181px;
    }


    @include media(portrait) {
      &_marginTop {
        margin-top: 197px;
      }
    }

    @include media(landscape) {
      &_marginTop {
        margin-top: 197px;
      }

      left: -157px;
      top: 0;
    }

    @include media(desktopSmall) {
      &_marginTop {
        max-width: 800px;
        margin-top: 221px;
      }

      left: -407px;
      top: 10px;
    }

    @include media(desktop) {
      &_marginTop {
        max-width: 800px;
        margin-top: 221px;
      }

      left: -407px;
      top: 0;
    }

    @include media(desktopBig) {
      &_marginTop {
        max-width: 800px;
        margin-top: 221px;
      }

      left: -407px;
      top: 0px;
    }

    @include media(desktopHuge) {
      &_marginTop {
        max-width: 800px;
        margin-top: 221px;
      }

      left: -407px;
      top: 0px;
    }
  }

  .title {
    margin-top: -7px;
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    text-align: center;
    font-family: 'KharkivTone', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    margin-bottom: 32px;

    &_accent {
      @include setProperty(color, var(--primary-500), var(--primary-500));
    }

    @include media(desktopSmall) {
      font-size: 48px;
      line-height: 56px;
    }

    @include media(desktop) {
      font-size: 48px;
      line-height: 56px;
    }

    @include media(desktopBig) {
      font-size: 48px;
      line-height: 56px;
    }

    @include media(desktopHuge) {
      font-size: 48px;
      line-height: 56px;
    }
  }

  .text {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    text-align: center;
    font-family: 'Montserrat';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 64px;

    @include media(desktopSmall) {
      font-size: 16px;
      line-height: 32px;
      letter-spacing: 0.183px;
    }

    @include media(desktop) {
      font-size: 16px;
      line-height: 32px;
      letter-spacing: 0.183px;
    }

    @include media(desktopBig) {
      font-size: 16px;
      line-height: 32px;
      letter-spacing: 0.183px;
    }

    @include media(desktopHuge) {
      font-size: 16px;
      line-height: 32px;
      letter-spacing: 0.183px;
    }
  }

  .btn {
    width: 100%;

    &_short {
      width: fit-content;
    }

    @include media(portrait) {
      width: fit-content;
    }

    @include media(landscape) {
      width: fit-content;
    }

    @include media(desktopSmall) {
      width: fit-content;
    }

    @include media(desktop) {
      width: fit-content;
    }

    @include media(desktopBig) {
      width: fit-content;
    }

    @include media(desktopHuge) {
      width: fit-content;
    }
  }
}

// animation
@keyframes slideinSmallGradient {

  0%,
  80%,
  100% {
    transform: translate(0, 0);
  }

  40% {
    transform: translate(212px, 4px);
  }

}

@keyframes slideinLargeGradient {

  0%,
  80%,
  100% {
    transform: translate(0, 0);
  }

  40% {
    transform: translate(-116px, 19px);
  }


}

@keyframes slideinNoise {

  0%,
  80%,
  100% {
    transform: translate(0, 0);
  }

  40% {
    transform: translate(70px, 68px);
  }

}

.animationGroup {
  position: absolute;
  top: 19%;
  right: 50%;
  transform: translateX(-50%);

  @include media(portrait) {
    top: 24%;
  }

  @include media(landscape) {
    top: 24%;
  }

  @include media(desktopSmall) {
    top: 24%;
  }

  @include media(desktop) {
    top: 24%;
  }

  @include media(desktopBig) {
    top: 24%;
  }

  @include media(desktopHuge) {
    top: 24%;
  }

  &__smallGradient {
    position: absolute;
    left: -91px;
    animation: infinite 4s 2s slideinSmallGradient;
    animation-delay: 3s;


    @include media(portrait) {
      left: -102px;
    }

    @include media(landscape) {
      left: -102px;
    }

    @include media(desktopSmall) {
      left: -102px;
    }

    @include media(desktop) {
      left: -102px;
    }

    @include media(desktopBig) {
      left: -102px;
    }

    @include media(desktopHuge) {
      left: -102px;
    }
  }

  &__largeGradient {
    position: absolute;
    left: 23px;
    animation: infinite 4s 2s slideinLargeGradient;
    animation-delay: 3s;

  }

  &__noise {
    position: absolute;
    left: -81px;
    animation: infinite 4s 2s slideinNoise;
    animation-delay: 3s;

    @include media(portrait) {
      left: -96px;
    }

    @include media(landscape) {
      left: -96px;
    }

    @include media(desktopSmall) {
      left: -96px;
    }

    @include media(desktop) {
      left: -96px;
    }

    @include media(desktopBig) {
      left: -96px;
    }

    @include media(desktopHuge) {
      left: -96px;
    }
  }
}

// animation END